import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import CompanyPage from "./pages/CompanyPage";
import PolicyPage from "./pages/PolicyPage";
import HDPPolicyPage from "./pages/HDPPolicy.jsx";
import UniversityPage from "./pages/UniversityPage";
import LibraryPage from "./pages/LibraryPage";
import HotelPage from "./pages/Hotel";
import HotelGroupPage from "./pages/HotelGropuBooking.jsx";
import AminitiesPage from "./pages/Aminities";
import SetHotels from "./pages/set_hotels.jsx";
import { ToastContainer } from "react-toastify";
import ProviderPage from "./pages/ProviderPage";
import { updateWorkSpace } from "./context/WorkSpace";
import SettingsPage from "./pages/SettingsPage";
import ProviderDetailsPage from "./pages/ProviderDetailsPage";
import HotelCategoryPage from "./pages/HotelCategoryPage";
import HotelImagePage from "./pages/HotelImagesPage";
import HotelCategoryImagePage from "./pages/HotelCategoryImagePage";
import SpinnerTTS from "./components/spinner/Spinner";
import REACT_APP from "./environment.js";
import HotelRoomPage from "./pages/HotelRoomsPage.jsx";
import HotelPolicyPage from "./pages/HotelPolicyPage.jsx";
import HotelInfoPage from "./pages/HotelInfoPage.jsx";
import HotelAmenityPage from "./pages/HotelAmenityPage.jsx";
import HotelCategoryAmenityPage from "./pages/HotelCategoryAmenityPage.jsx";
import BookingPage from "./pages/Booking.jsx";
import HotelContactPage from "./pages/HotelContactPage.jsx";
import HotelStaffLogsPage from "./pages/HotelStaffLogsPage.jsx";
import HotelAddressPage from "./pages/HotelAddressPage.jsx";
import CitiesPage from "./pages/Cities.jsx";
import RegionsPage from "./pages/Regions.jsx";
import BookingType from "./components/BookingType/index.jsx";
import Alerts from "./components/Alerts/index.jsx";
import Types from "./components/HotelTypes/index.jsx";
import AddAlerts from "./components/Alerts/AddAlert.jsx";
import UpdateAlerts from "./components/Alerts/UpdateAlert.jsx";
import CountriesPage from "./pages/Countries.jsx";
import ReportsPage from "./pages/ReportsPage.jsx";
import Set_one_view from "./pages/set_one_set_view.jsx";
import Set_update_hotels from "./pages/set_update_hotels.jsx";
import WorkSpacePage from "./pages/WorkSpacePage.jsx";
import HotelTelegramPage from "./pages/HotelTelegramPage.jsx";

function getQueryParam(key, qst = window.location.search) {
    return qst.slice(
        qst.indexOf(key + "=") + key.length + 1,
        qst.slice(qst.indexOf(key + "=") + key.length + 1).indexOf("&") !== -1
            ? qst.slice(qst.indexOf(key + "=") + key.length + 1).indexOf("&") + key.length + 2
            : qst.length
    );
}

const App = () => {
    const [workSpace] = useContext(updateWorkSpace);
    const [wait, setWait] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setWait(false);
        }, 1500);
        navigate(`${window.location.pathname}`);
    }, []);

    const token = sessionStorage.getItem("token") || getQueryParam("user");
    if (!token) {
        window.onbeforeunload = {};
        window.location.replace(REACT_APP.LOGIN_URL);
    } else sessionStorage.setItem("token", token);

    return wait ? (
        <SpinnerTTS />
    ) : (
        <div className="app_wrapper">
            <ToastContainer />
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/workspace" element={<WorkSpacePage />} />

                    {workSpace === "hrp" && (
                        <React.Fragment>
                            <Route path="/company" element={<CompanyPage />} />
                            <Route path="/policy" element={<PolicyPage />} />
                            <Route path="/university" element={<UniversityPage />} />
                            <Route path="/library-files" element={<LibraryPage />} />
                            <Route path="/provider" element={<ProviderPage />} />
                            <Route path="/provider/:id" element={<ProviderDetailsPage />} />
                        </React.Fragment>
                    )}
                    {workSpace === "hdp" && (
                        <React.Fragment>
                            <Route path="/hotel" element={<HotelPage />} />
                            <Route path="/hotel/groupbooking/:hotelid" element={<HotelGroupPage />} />
                            <Route path="/amenities" element={<AminitiesPage />} />
                            <Route path="/policy" element={<HDPPolicyPage />} />
                            <Route path="/set" element={<SetHotels />} />
                            <Route path="/set/:set_id" element={<Set_one_view />} />
                            <Route path="/set/update/:set_id" element={<Set_update_hotels />} />
                            <Route path="/booking" element={<BookingPage />} />
                            <Route path="/reports" element={<ReportsPage />} />
                            <Route path="/hotel/:hotelid/room" element={<HotelRoomPage />} />
                            <Route path="/hotel/:hotelid/category" element={<HotelCategoryPage />} />
                            <Route path="/hotel/:hotelid/info" element={<HotelInfoPage />} />
                            <Route path="/hotel/:hotelid/contact" element={<HotelContactPage />} />
                            <Route path="/hotel/:hotelid/amenity" element={<HotelAmenityPage />} />
                            <Route path="/hotel/:hotelid/category/:categoryid/images" element={<HotelCategoryImagePage />} />
                            <Route path="/hotel/:hotelid/category/:categoryid/amenity" element={<HotelCategoryAmenityPage />} />
                            <Route path="/hotel/:hotelid/images" element={<HotelImagePage />} />
                            <Route path="/hotel/:hotelid/staff_logs" element={<HotelStaffLogsPage />} />
                            <Route path="/hotel/:hotelid/address" element={<HotelAddressPage />} />
                            <Route path="/hotel/:hotelid/telegram" element={<HotelTelegramPage />} />
                            <Route path="/hotel/:hotelid/policy" element={<HotelPolicyPage />} />
                            <Route path="/booking-type" element={<BookingType />} />
                            <Route path="/alerts" element={<Alerts />} />
                            <Route path="/types" element={<Types />} />
                            <Route path="/create-alerts" element={<AddAlerts />} />
                            <Route path="/update-alerts/:alertId" element={<UpdateAlerts />} />
                            <Route path="/settings" element={<SettingsPage />} />
                        </React.Fragment>
                    )}
                    {workSpace === "common" && (
                        <React.Fragment>
                            <Route path="/cities" element={<CitiesPage />} />
                            <Route path="/regions" element={<RegionsPage />} />
                            <Route path="/countries" element={<CountriesPage />} />
                        </React.Fragment>
                    )}
                    <Route path="/*" element={<Navigate to="/home" />} />
                </Routes>
            </div>
        </div>
    );
};

export default App;
