import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";

export default function OpenOnBooking({ id, setIsWaiting,data, currentStatus, name, getAllHotels, handleMenuClose }) {
    console.log(data)
    const [open, setOpen] = React.useState(false);
    const [selectedStatus, setSelectedStatus] = React.useState(data.hbp_status_id || "inactive");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const updateStatus = async () => {
        handleClose();
        setIsWaiting(true);
        await axios
            .put(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/view_hbp/${id}`,
                { in_hbp: selectedStatus }, // Yangi statusni yuboramiz
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": REACT_APP.CLIENTID,
                        "client-secret": REACT_APP.SECRET,
                    },
                }
            )
            .then(() => {
                setIsWaiting(false);
                toast.success("Status successfully updated", { autoClose: 2000 });
                handleMenuClose();
                getAllHotels();
            })
            .catch((err) => {
                setIsWaiting(false);
                handleMenuClose();
                console.log(err);
                toast.error(err?.response?.data?.errors[0]?.message || "Something went wrong");
            });
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Update Status</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Update Hotel Status</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Select the new status for the hotel "{name}".
                    </DialogContentText>
                    <Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="2">Open</MenuItem>
                        <MenuItem value="1">Close</MenuItem>
                        <MenuItem value="3">Inactive</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={updateStatus}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

