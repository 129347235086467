import React from "react";
import HotelAddress from "../components/Hotel/HotelAddress/AddressData";
import { Box } from "@mui/material";
import HotelTabs from "../components/Hotel/Tabs";

const HotelAddressPage = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <HotelTabs />
      <HotelAddress />;
    </Box>
  );
};

export default HotelAddressPage;
