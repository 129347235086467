import AddAlert from "./AddAlert";
import MessageProvider from "./Context";
import { useState } from "react";
import Table from "./Table";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BookingType = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="container py-5">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <Button variant="contained"  onClick={()=>{navigate("/create-alerts")}}> Add alert</Button>
                <Table setIsWaiting={setIsWaiting} setBookingType={""} />
            </MessageProvider>
        </div>
    )
}

export default BookingType;