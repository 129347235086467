import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ENV from "../../../environment";
import { toast } from "react-toastify";
import { Box, Typography, TextField, Button, CircularProgress, Paper } from "@mui/material";

export default function ChatId() {
  const [loading, setLoading] = useState(true);
  const [chatId, setChatId] = useState("");
  const [initialChatId, setInitialChatId] = useState(""); // API-dan kelgan chat ID-ni saqlash uchun
  const [recordId, setRecordId] = useState(""); // Response'dagi id uchun holat
  const { hotelid } = useParams();

  // Fetch the current chat_id va id
  const getChatId = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${ENV.URL}/api/hdp/tts/adm/hotel/chat_id/${hotelid}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      setChatId(response.data?.telegram_chat_id || ""); // Chat ID o'rnatish
      setInitialChatId(response.data?.telegram_chat_id || ""); // Original chat ID-ni saqlash
      setRecordId(response.data?.id || null); // id qiymatini saqlash
    } catch (error) {
      toast.error("Failed to fetch Chat ID");
    } finally {
      setLoading(false);
    }
  };

  // Handle adding chat_id
  const addChatId = async () => {
    if (!chatId) return;

    try {
      const response = await axios.post(
        `${ENV.URL}/api/hdp/tts/adm/hotel/chat_id`,
        { hotel_id: hotelid, telegram_chat_id: chatId },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      toast.success("Chat ID added successfully");
      setRecordId(response.data.id); // Yangi yozuv ID-ni o'rnatish
      setInitialChatId(chatId); // Yangi chat ID-ni original qiymatga o'rnatish
      getChatId(); // Refresh after successful save
    } catch (error) {
      toast.error("Failed to add Chat ID");
    }
  };

  // Handle updating chat_id
  const updateChatId = async () => {
    if (!chatId || recordId === null || chatId === initialChatId) return;

    try {
      await axios.put(
        `${ENV.URL}/api/hdp/tts/adm/hotel/chat_id/${hotelid}`, // id parametr sifatida ishlatiladi
        { telegram_chat_id: chatId },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      toast.success("Chat ID updated successfully");
      setInitialChatId(chatId); // Yangi qiymatni original qiymatga o'rnatish
      getChatId(); // Refresh after successful update
    } catch (error) {
      toast.error("Failed to update Chat ID");
    }
  };

  // Handle deleting chat_id
  const deleteChatId = async () => {
    if (recordId === null) return;

    try {
      await axios.delete(
        `${ENV.URL}/api/hdp/tts/adm/hotel/chat_id/${recordId}`, // id parametr sifatida ishlatiladi
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      toast.success("Chat ID deleted successfully");
      setChatId(""); // Inputni tozalash
      setInitialChatId(""); // Original qiymatni tozalash
      setRecordId(null); // ID-ni tozalash
    } catch (error) {
      toast.error("Failed to delete Chat ID");
    }
  };

  useEffect(() => {
    getChatId();
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      px={2}
    >
      {loading ? (
        <CircularProgress size={80} color="primary" />
      ) : (
        <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: "100%" }}>
          <Typography variant="h5" gutterBottom color="primary" align="center">
            Telegram Chat ID Management
          </Typography>
          <Box mb={2}>
            <TextField
              label="Chat ID"
              fullWidth
              type="number"
              value={chatId}
              onChange={(e) => setChatId(e.target.value)}
              placeholder="Enter or update Chat ID"
              variant="outlined"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap={2}>
            {!recordId ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={addChatId}
                disabled={!chatId} // Add tugmasi chatId bo'sh bo'lsa disable
              >
                Add Chat ID
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={updateChatId}
                  disabled={!chatId || chatId === initialChatId} // Update tugmasi shartlar asosida disable
                >
                  Update Chat ID
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={deleteChatId}
                >
                  Delete Chat ID
                </Button>
              </>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
}
