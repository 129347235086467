import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import axios from "axios";
import { toast } from "react-toastify";
import { MessageContext } from "./Context";
import REACT_APP from "../../../../environment";

const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required").max(255),
    last_name: Yup.string().required("Last name is required").max(255),
    middle_name: Yup.string().max(255),
    login: Yup.string().required("Login is required").max(255),
    password: Yup.string().required("Password is required").max(255),
    role: Yup.number().required("Role is required"),
    phone: Yup.string().max(255),
    email: Yup.string()
        .required("Email is required")
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, "Invalid email format"),
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const AddSet = () => {
    const { set_id } = useParams(); // Extract set_id from the URL
    const [, setMessage] = useContext(MessageContext);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                ...values,
                hotel_set_id: parseInt(set_id, 10), // Include set_id in the payload
            };

            const response = await axios.post(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/register/user`,
                payload,
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": REACT_APP.CLIENTID,
                        "client-secret": REACT_APP.SECRET,
                    },
                }
            );

            if (response.status === 200) {
                setMessage((prev) => !prev);
                toast.success("Corporate user created successfully");
                handleClose();
            }
        } catch (error) {
            console.error("Error creating corporate user:", error.response?.data || error.message);
            toast.error(
                error.response?.data?.message || "Failed to create corporate user"
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
                <Button variant="contained" style={{ margin: "10px" }} color="primary" onClick={handleOpen}>
                    Add Corporate User
                </Button>
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Add New Corporate User
                    </Typography>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            middle_name: "",
                            login: "",
                            password: "",
                            role: "", // Role will be selected from the dropdown
                            phone: "",
                            email: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <Form>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="first_name"
                                            label="First Name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="last_name"
                                            label="Last Name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="middle_name"
                                            label="Middle Name (Optional)"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="login"
                                            label="Login"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="password"
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="role-select-label">Role</InputLabel>
                                            <Select
                                                labelId="role-select-label"
                                                value={values.role}
                                                onChange={(e) => setFieldValue("role", e.target.value)}
                                                label="Role"
                                            >
                                                <MenuItem value={1}>Owner</MenuItem>
                                                <MenuItem value={2}>Manager</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="phone"
                                            label="Phone (Optional)"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="email"
                                            label="Email"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                        <Button onClick={handleClose} variant="contained" color="error">
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div>
    );
};

export default AddSet;
