import React from "react";
import HotelTelegram from "../components/Hotel/HotelTelegram/ChatId";
import { Box } from "@mui/material";
import HotelTabs from "../components/Hotel/Tabs";

const HotelTelegramPage = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <HotelTabs />
      <HotelTelegram />;
    </Box>
  );
};

export default HotelTelegramPage;
