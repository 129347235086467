import { getHotelFilter, setHFPage } from "../../store/hotel-table-filer.js";
import { useContext, useState, Fragment, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import REACT_APP from "../../environment.js";
import Spinner from "react-spinner-material";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { Message } from "./Context.js";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Action from "./Action.js";
import axios from "axios";

const truncateMessage = (message, limit) => {
    if (message.length > limit) {
        return `${message.substring(0, limit)} ...`; // Matnni qisqartirish
    }
    return message;
};
const headCells = [
    { id: "id", numeric: false, label: "ID", align: "left" },
    { id: "to", numeric: false, label: "Number of hotels", align: "left" },
    { id: "send", numeric: false, label: "Is Sent", align: "left" },
    { id: "alertCreateDate", numeric: false, label: "Created Date", align: "left" },
    { id: "alertExpirationDate", numeric: false, label: "Expiration Date", align: "left" },
    { id: "alertMessage", numeric: false, label: "Message", align: "left", width:"300px" },
    { id: "createdBy", numeric: false, label: "Created By", align: "left" },
    { id: "actions", numeric: true, label: "Actions", align: "center" },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sx={{ width: headCell.width || "auto" }} align={headCell.align}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = { rowCount: PropTypes.number.isRequired };

export default function EnhancedTable({ setIsWaiting, setBookingType }) {
    const [message] = useContext(Message);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState(0);

    const dispatch = useDispatch();
    const hotelFiler = useSelector(getHotelFilter);
    const rowsPerPage = 10;
    const [page, setPage] = useState(hotelFiler.page);

    const getAlerts = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/alerts`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
                params: {
                    page,
                    limit: rowsPerPage,
                },
            });
            setData(response.data);
            setDataLength(response.data.length);
            setLoading(false);
            setError(false);
        } catch (error) {
            console.error("Error fetching alerts:", error);
            setError(true);
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        dispatch(setHFPage(newPage));
        setPage(newPage);
    };

    useEffect(() => {
        getAlerts();
    }, [message, page]);

    return (
        <Fragment>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            No alerts found
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            Could not fetch alerts
                        </Alert>
                    )}
                    {data.length > 0 && !error && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead rowCount={data.length} />
                                    <TableBody>
                                        {data.map((alert) => (
                                            <TableRow hover tabIndex={-1} key={alert.id}>
                                                <TableCell align="left">{alert.id}</TableCell>
                                                <TableCell align="center">{alert.hotel_count }</TableCell>
                                                <TableCell align="center">{alert?.isSent ? "Yes" : "No"}</TableCell>
                                                <TableCell align="left">{new Date(alert.alertCreateDate).toLocaleString()}</TableCell>
                                                <TableCell align="left">{new Date(alert.alertExpirationDate).toLocaleString()}</TableCell>
                                                <TableCell align="left">{truncateMessage(alert.alertMessage,250)}</TableCell>
                                                <TableCell align="left">{alert.createdBy}</TableCell>
                                                <TableCell align="center">
                                                    <Action
                                                        id={alert.id}
                                                        data={alert}
                                                        getAllAlerts={getAlerts}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={dataLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </Fragment>
    );
}
