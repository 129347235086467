import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { useParams } from "react-router-dom";
import Set_update_hotels from "./index";
import Set_users from "./set_users/index.js";
import ENV from "../../../environment";
import axios from "axios";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
}

export default function HotelUserTabs() {
    const { set_id } = useParams();
    const [value, setValue] = React.useState(1); // Set default to 1 (Hotels tab)
    const [loading, setLoading] = useState(true);
    const [setData, setSetData] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchSets = async () => {
        setLoading(true);
        try {
            const res2 = await axios.get(
                `${ENV.URL}/api/hdp/tts/adm/hotel/sets/${set_id}`,
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": ENV.CLIENTID,
                        "client-secret": ENV.SECRET,
                    },
                }
            );
            const set = res2?.data?.data?.set;
            if (set) {
                setSetData(set);
            } else {
                console.error("Set data not found");
            }
        } catch (error) {
            console.error("Failed to fetch set data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSets();
    }, [set_id]);

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="hotel and user tabs">
                <Tab
                    label={<div>{setData?.brand_name}</div>}
                    disabled
                    {...a11yProps(90)}
                />
                <Tab label="Hotels" {...a11yProps(1)} />
                <Tab label="Users" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={1}>
                <Set_update_hotels />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Set_users />
            </CustomTabPanel>
        </Box>
    );
}
