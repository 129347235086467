import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ENV from "../../../environment";
import { toast } from "react-toastify";
import Spinner from "react-spinner-material";
import Modal from "./UpdateAddress";

export default function AddressData() {
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState([]);
  const { hotelid } = useParams();

  const getData = async () => {
    await axios
      .get(`${ENV.URL}/api/hdp/tts/adm/hotel/address/${hotelid}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        setAddress(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            marginTop: "10vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
        </div>
      ) : (
        <div className="address-page">
          <div className="address-card">
            <div className="address-title">Address Details</div>
            <div className="address-field">
              <span className="address-label">Address 1:</span>{" "}
              {address.address1}
            </div>
            {address.address2 && (
              <div className="address-field">
                <span className="address-label">Address 2:</span>{" "}
                {address.address2}
              </div>
            )}
            <div className="address-field">
              <span className="address-label">City:</span> {address.city}
            </div>
            <div className="address-field">
              <span className="address-label">Region:</span> {address.region}
            </div>
            {address.zip_code && (
              <div className="address-field">
                <span className="address-label">ZIP Code:</span>{" "}
                {address.zip_code}
              </div>
            )}
            <div className="address-field">
              <span className="address-label">Country:</span> {address.country}
            </div>
          <Modal getTableData={getData}/>               
          </div>
        </div>
      )}
    </>
  );
}
