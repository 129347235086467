import React, { useEffect, useState } from "react";
import Table from "./Table";
import AddType from "./AddType";
import "./style.css";
import MessageProvider from "./Context";
import REACT_APP from "../../environment";
import axios from "axios";

const Hotel = () => {
    const [isWaiting, setIsWaiting] = useState(false);
    const [types, setTypes] = useState([]);

    const getTypes = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/policy/type`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => setTypes(res.data.data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getTypes();
    }, []);

    return (
        <div className="container">
            {isWaiting && <div className="ca-overlay"></div>}
            <MessageProvider>
                <Table setIsWaiting={setIsWaiting} types={types} />
                <AddType types={types} />
            </MessageProvider>
        </div>
    );
};

export default Hotel;
