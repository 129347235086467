import React from "react";
import WorkSpace from "../components/WorkSpace/Index-2";

const WorkSpacePage = () => {
    return (
        <>
            <WorkSpace />
        </>
    );
};

export default WorkSpacePage;
