import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../../../environment";

const Action = ({ set, fetchSets }) => {
    const [anchorEl, setAnchorEl] = useState(null); // To handle menu open state
    const [loading, setLoading] = useState(false); // Prevent duplicate requests

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAction = async (actionType) => {
        if (loading) return; // Prevent duplicate requests
        setLoading(true); // Start loading

        const endpoint =
            actionType === "activate"
                ? `${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/users/active/${set.id}`
                : `${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/users/block/${set.id}`;

        try {
            const response = await axios.get(endpoint, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            });

            const successMessage =
                actionType === "activate"
                    ? `User ${set.first_name} activated successfully.`
                    : `User ${set.first_name} blocked successfully.`;

            toast.success(successMessage);

            // Refresh the table after successful action
            fetchSets();
        } catch (error) {
            const errorMessage =
                actionType === "activate"
                    ? `Failed to activate user ${set.first_name}.`
                    : `Failed to block user ${set.first_name}.`;

            console.error(errorMessage, error);
            toast.error(errorMessage);
        } finally {
            setLoading(false); // End loading
            handleMenuClose(); // Close the menu
        }
    };

    return (
        <div>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem
                    disabled={set.account_status === 4 || loading}
                    onClick={() => handleAction("activate")}
                >
                    Activate User
                </MenuItem>
                <MenuItem
                    disabled={set.account_status === 3 || loading}
                    onClick={() => handleAction("block")}
                    style={{ color: "red" }}
                >
                    Block User
                </MenuItem>
            </Menu>
        </div>
    );
};

export default Action;
