import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import REACT_APP from "../../environment.js";
import axios from "axios";
import { toast } from "react-toastify";

const WorkSpace = ({ data }) => {
    const navigate = useNavigate();
    const [env, setEnv] = useState(data);
    const [isWaiting, setIsWaiting] = useState(false);
    const handleChange = (val) => setEnv({ ...env, [val]: !env[val] });
    const submit = async () => {
        try {
            setIsWaiting(true);
            const headers = { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET };
            await axios.put(`${REACT_APP.URL}/api/hdp/tts/adm/crypt/env`, env, { headers });
            navigate("/hotel");
            toast.success("Updated successfully!", { autoClose: 2000 });
        } catch (error) {
            setIsWaiting(false);
            const message = error?.response?.data?.errors && error.response.data.errors[0].message;
            toast.error(message || "An error occurred");
        }
    };

    return (
        <div>
            <h2 style={{ margin: "20px" }}>Encrypted environments</h2>
            <div style={{ marginLeft: "20px" }}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={env.development} onChange={() => handleChange("development")} />} label="development" />
                    <FormControlLabel control={<Checkbox checked={env.production} onChange={() => handleChange("production")} />} label="production" />
                </FormGroup>
                <Button type="button" variant="contained" color="success" style={{ marginTop: "20px" }} onClick={submit} disabled={isWaiting}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default WorkSpace;
