import React, { useState } from "react";
import { Grid, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { FormControl, FormControlLabel, FormLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Button } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import ENV from "../../../environment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const UpdateHotelPolicy = ({ open, setOpen, data, getData }) => {
    const initialValues = data.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
    }, {});

    const [loading, setLoading] = useState(false);
    const { hotelid } = useParams();

    const handleClose = () => setOpen(false);

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;
    const validate = Yup.array().of(
        Yup.object().shape({
            id: Yup.number().integer().min(1).nullable().required("Field is required"),
            policy_id: Yup.number().integer().min(1).required("Field is required"),
            name: Yup.string().required("Field is required"),
            type: Yup.string().oneOf(["time", "boolean", "integer"]).required("Field is required"),
            value: Yup.mixed().when("type", {
                is: "time",
                then: Yup.string().matches(timeRegex, "SS:MM").nullable().required("Field is required"),
                otherwise: Yup.mixed().when("type", {
                    is: "boolean",
                    then: Yup.boolean().nullable().required("Field is required"),
                    otherwise: Yup.mixed().when("type", {
                        is: "integer",
                        then: Yup.number().integer().nullable().required("Field is required"),
                        otherwise: Yup.mixed().notRequired(),
                    }),
                }),
            }),
        })
    );

    const onSubmit = async (values, { resetForm }) => {
        setLoading(true);
        const body = data.map((el) => ({ id: el.id, name: el.name, type: el.type, policy_id: el.policy_id, value: values[el.name] }));
        await axios
            .put(`${ENV.URL}/api/hdp/tts/adm/hotel/hotel-policy/${hotelid}`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": ENV.CLIENTID,
                    "client-secret": ENV.SECRET,
                },
            })
            .then((res) => {
                setLoading(false);
                getData();
                toast.success("Saved successfully", { autoClose: 2000 });
                resetForm({ values: "" });
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="modal_res">
                        <>
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom" style={{ marginBottom: "1rem" }}>
                                {"Enter policy info"}
                            </Typography>

                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {() => (
                                    <Form>
                                        <Grid container spacing={4}>
                                            {data.map((item) => (
                                                <Grid item xs={6} key={item.id}>
                                                    {item.type === "boolean" ? (
                                                        <Field id={item.id} name={item.name} as="div">
                                                            {({ field }) => (
                                                                <>
                                                                    <FormControl component="fieldset">
                                                                        <FormLabel component="legend">{item.name}</FormLabel>
                                                                        <RadioGroup row {...field}>
                                                                            <FormControlLabel value={false} control={<Radio />} label={"No"} />
                                                                            <FormControlLabel value={true} control={<Radio />} label={"Yes"} />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </>
                                                            )}
                                                        </Field>
                                                    ) : item.type === "time" ? (
                                                        <Grid item xs={6}>
                                                            <FormLabel component="legend">{item.name}</FormLabel>
                                                            <Field fullWidth id={item.id} name={item.name} size="small" component={TextField} type="time" />
                                                        </Grid>
                                                    ) : item.name === "Cancel booking" ? (
                                                        <Grid item xs={6}>
                                                            <FormLabel component="legend">{item.name}</FormLabel>
                                                            <Field name={item.name}>
                                                                {({ field }) => (
                                                                    <FormControl fullWidth size="small">
                                                                        <Select
                                                                            onChange={(e) => {
                                                                                const selectedValue = e.target.value === "" ? null : Number(e.target.value);
                                                                                field.onChange(selectedValue);
                                                                            }}
                                                                            {...field}
                                                                            label={item.name}
                                                                        >
                                                                            <MenuItem value={0}>{"imposible cancel"}</MenuItem>
                                                                            <MenuItem value={1}>{"cancel to 24 hours"}</MenuItem>
                                                                            <MenuItem value={2}>{"cancel to 48 hours"}</MenuItem>
                                                                            <MenuItem value={3}>{"cancel to 72 hours"}</MenuItem>
                                                                            <MenuItem value={Infinity}>{"can always cancel"}</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                    ) : (
                                                        <Grid item xs={6}>
                                                            <FormLabel component="legend">{item.name}</FormLabel>
                                                            <Field type="text" id={item.id} name={item.name} />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <div style={{ float: "right", display: "flex", gap: "20px" }}>
                                                    <Button variant="contained" color="error" className="mr-2" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="contained" color="primary" type="submit">
                                                        UPDATE
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </>
                        {loading && <div className="overlay" />}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default UpdateHotelPolicy;
