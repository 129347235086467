/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { Message } from "./Context";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({ id, handleMenuClose, getTypes }) {
    const [, setMessage] = useContext(Message);
    const [loading, setLoading] = useState(false);
    const [wait, setWait] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const handleOpen = async () => {
        setOpen(true);
        await fetchData(); // API chaqiruv modal ochilganda amalga oshiriladi
    };

    const handleClose = () => {
        setOpen(false);
        setData(null); // Modal yopilganda eski ma'lumotlarni tozalash
    };

    const fetchData = async () => {
        setWait(true);
        try {
            const res = await axios.get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/types/${id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            });
            setData(res.data.data);
        } catch (err) {
            toast.error("An error occurred while fetching data.");
            console.error(err.message);
        } finally {
            setWait(false);
        }
    };

    const validation = Yup.object({
        name: Yup.string()
            .required("Name is required")
            .matches(regex.text, { message: "Enter valid name" }),
    });

    const onUpdate = async (values) => {
        const payload = { name: values.name };
        setMessage(true);
        setLoading(true);
        try {
            await axios.put(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/types/${id}`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            });
            await getTypes();
            toast.success("Updated successfully!");
            handleClose();
            handleMenuClose();
        } catch (err) {
            toast.error("An error occurred while updating.");
            console.error(err.message);
        } finally {
            setLoading(false);
            setMessage(false);
        }
    };

    return (
        <div className="addhotel">
            <Button onClick={handleOpen}>Update</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            Update type
                        </Typography>
                        {wait ? (
                            <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                            </div>
                        ) : (
                            <Formik
                                enableReinitialize
                                initialValues={data || { name: "" }} // Default empty value
                                validationSchema={validation}
                                onSubmit={(values) => onUpdate(values)}
                            >
                                {() => (
                                    <Form autoComplete="off" className="mt-4">
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field
                                                    fullWidth
                                                    label="Type name"
                                                    name="name"
                                                    component={TextField}
                                                    autoComplete="off"
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="left_right">
                                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                                Close
                                            </Button>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    Update
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    Update
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
