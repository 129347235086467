import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "react-spinner-material";
import ENV from "../../environment";
import { useNavigate } from "react-router-dom";

const CreateAlert = () => {
    const [alertMessage, setAlertMessage] = useState("");
    const [alertExpirationDate, setAlertExpirationDate] = useState("");
    const [freeHotels, setFreeHotels] = useState([]);
    const [assignedHotels, setAssignedHotels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [draggedHotel, setDraggedHotel] = useState(null);
    const [assignMode, setAssignMode] = useState("manual"); // "all" or "manual"
    const navigate = useNavigate();

    useEffect(() => {
        fetchHotels();
    }, []);

    const fetchHotels = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${ENV.URL}/api/hdp/tts/adm/hotel?page=0&limit=100`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": ENV.CLIENTID,
                    "client-secret": ENV.SECRET,
                },
            });

            if (res.data.status === "success") {
                setFreeHotels(res.data.data.hotels || []);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error("Failed to fetch hotels");
        }
    };

    const handleDragStart = (hotel, origin) => {
        if (assignMode === "manual") {
            setDraggedHotel({ hotel, origin });
        }
    };

    const handleDrop = (target) => {
        if (draggedHotel && assignMode === "manual") {
            const { hotel, origin } = draggedHotel;

            if (origin === "free" && target === "assigned") {
                setFreeHotels(freeHotels.filter((h) => h.id !== hotel.id));
                setAssignedHotels([...assignedHotels, hotel]);
            } else if (origin === "assigned" && target === "free") {
                setAssignedHotels(assignedHotels.filter((h) => h.id !== hotel.id));
                setFreeHotels([...freeHotels, hotel]);
            }

            setDraggedHotel(null);
        }
    };

    const handleAssignToAll = () => {
        setAssignedHotels([...assignedHotels, ...freeHotels]); // Move all free hotels to assigned hotels
        setFreeHotels([]); // Clear the free hotels list
    };

    const handleManualSelection = () => {
        setFreeHotels([...freeHotels, ...assignedHotels]); // Move all assigned hotels back to free hotels
        setAssignedHotels([]); // Clear the assigned hotels list
    };

    const handleSave = async () => {
        if (!alertMessage || !alertExpirationDate) {
            toast.error("Please fill in all fields");
            return;
        }
    
        // Collect all hotel IDs if assignMode is "all"
        const allHotelIds = [...freeHotels, ...assignedHotels].map((hotel) => hotel.id);
    
        const dataToSend = {
            alertMessage,
            alertExpirationDate,
            hotels: assignMode === "all" ? allHotelIds : assignedHotels.map((hotel) => hotel.id),
        };
    
        try {
            await axios.post(`${ENV.URL}/api/hdp/tts/adm/hotel/alerts`, dataToSend, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": ENV.CLIENTID,
                    "client-secret": ENV.SECRET,
                },
            });
            navigate("/alerts");
            toast.success("Alert created successfully!");
        } catch (err) {
            toast.error("Failed to create alert");
        }
    };
    
    const filteredFreeHotels = freeHotels.filter((hotel) =>
        hotel.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box className="container mt-4">
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "100px",
                    }}
                >
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Grid container spacing={2}>
                    {/* Alert Message Input */}
                    <Grid item xs={12}>
                        <Typography variant="h5">Create New Alert</Typography>
                        <Divider sx={{ my: 2 }} />
                    </Grid>

                    {/* Radio Buttons for Assign Mode */}
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            value={assignMode}
                            onChange={(e) => {
                                const mode = e.target.value;
                                setAssignMode(mode);
                                if (mode === "all") {
                                    handleAssignToAll();
                                } else if (mode === "manual") {
                                    handleManualSelection();
                                }
                            }}
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="Assign to All Hotels"
                            />
                            <FormControlLabel
                                value="manual"
                                control={<Radio />}
                                label="Manual Selection"
                            />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Alert Message"
                            value={alertMessage}
                            onChange={(e) => setAlertMessage(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>

                    {/* Expiration Date Input */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="datetime-local"
                            label="Expiration Date"
                            value={alertExpirationDate}
                            onChange={(e) => setAlertExpirationDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Grid>

                    {/* Free Hotels Section */}
                    <Grid item xs={6}>
                        <Paper
                            elevation={3}
                            sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => handleDrop("free")}
                        >
                            <Typography variant="h6">Free Hotels</Typography>
                            {assignMode === "manual" && (
                                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                    <TextField
                                        placeholder="Search Free Hotels"
                                        variant="outlined"
                                        size="small"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        fullWidth
                                    />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    mt: 2,
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                }}
                            >
                                {filteredFreeHotels.map((hotel) => (
                                    <Box
                                        key={hotel.id}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            border: "1px solid #1976d2",
                                            borderRadius: 1,
                                            p: 1,
                                            mb: 1,
                                            cursor: assignMode === "manual" ? "grab" : "default",
                                        }}
                                        draggable={assignMode === "manual"}
                                        onDragStart={() => handleDragStart(hotel, "free")}
                                    >
                                        {hotel.name}
                                    </Box>
                                ))}
                                {filteredFreeHotels.length === 0 && (
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        No available free hotels
                                    </Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Assigned Hotels Section */}
                    <Grid item xs={6}>
                        <Paper
                            elevation={3}
                            sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => handleDrop("assigned")}
                        >
                            <Typography variant="h6">Assigned Hotels</Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    maxHeight: "500px",
                                    overflowY: "auto",
                                }}
                            >
                                {assignedHotels.map((hotel) => (
                                    <Box
                                        key={hotel.id}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            border: "1px solid #1976d2",
                                            borderRadius: 1,
                                            p: 1,
                                            mb: 1,
                                            cursor: assignMode === "manual" ? "grab" : "default",
                                        }}
                                        draggable={assignMode === "manual"}
                                        onDragStart={() => handleDragStart(hotel, "assigned")}
                                    >
                                        {hotel.name}
                                    </Box>
                                ))}
                                {assignedHotels.length === 0 && (
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        No hotels assigned
                                    </Typography>
                                )}
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Save Button */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={
                                !alertMessage || !alertExpirationDate
                            }
                        >
                            Send Alert to hotels
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default CreateAlert;
