import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FormControls, FormControlWrapper, ListBtn, Wrapper } from "../../styles/ListStyle";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import REACT_APP from "../../environment";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

const List = () => {
    const [loading, setLoading] = useState(false);
    const [policy, setPolicy] = useState([]);
    const [data, setData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [company_id, setCompany_id] = useState("");
    const [companyPolicy, setCompanyPolicy] = useState([]);
    const [boards, setBoards] = useState([
        {
            id: 1,
            title: "Policy",
            items: [],
        },
    ]);
    const [boards2, setBoards2] = useState([
        {
            id: 1,
            title: "",
            items: [],
        },
    ]);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const dragOverHandler = (e) => {
        e.preventDefault();
    };
    const dragLeaveHandler = (e) => {
        e.target.style.boxShadow = "none";
    };
    const dragStartHandler = (e, board, item) => {
        setCurrentBoard(board);
        setCurrentItem(item);
    };
    const DropHandler = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setCompanyPolicy([...companyPolicy, currentItem]);
        setBoards2(
            boards.map((b) => {
                if (b.id === board.id) {
                    return board;
                }
                if (b.id === currentBoard.id) {
                    return currentBoard;
                }
                return b;
            })
        );
    };
    const DropHandler2 = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setPolicy([...policy, currentItem]);
        setBoards(
            boards.map((b) => {
                if (b.id === board.id) {
                    return board;
                }
                if (b.id === currentBoard.id) {
                    return currentBoard;
                }
                return b;
            })
        );
    };
    const dragEndHandler = (e) => {
        e.target.style.boxShadow = "none";
    };
    function getPolicy() {
        axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/policy`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((response) => {
                setData(response.data.data);
                setPolicy(response.data.data);
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    function getCompany() {
        axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/company`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((response) => {
                setCompanies(response.data.data);
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const handleChange = (e) => {
        setBoards2([
            {
                id: 1,
                title: e.target.value.name,
                items: [],
            },
        ]);
        setCompany_id(e.target.value);
        axios
            .get(`${REACT_APP.URL}/api/hrp/tts/adm/policy/company/${e.target.value.id}/policy`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((response) => {
                setPolicy(data.filter((value) => response.data.data.map((e) => e.policy_id).indexOf(value.id) === -1));
                setCompanyPolicy(response.data.data);
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const onSave = () => {
        setLoading(true);
        const data = {
            company_id: company_id.id,
            policies: [],
        };
        for (let i = 0; i < companyPolicy.length; i++) {
            data.policies.push(companyPolicy[i].id);
        }
        axios
            .patch(`${REACT_APP.URL}/api/hrp/tts/adm/policy/company`, data, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success("Submitted successfully", { autoClose: 2000 });
            })
            .catch((err) => {
                setLoading(false);
                toast.error("An error occurred");
            });
    };

    useEffect(() => {
        getPolicy();
        getCompany();
    }, []);
    return (
        <Wrapper>
            <FormControlWrapper>
                <FormControls>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Select Company</InputLabel>
                        <Select
                            placeholder="Select policy"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={company_id}
                            label="Select policy"
                            onChange={handleChange}
                        >
                            {companies.map((value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        {value.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </FormControls>
            </FormControlWrapper>
            <div className="DropContainer">
                {boards.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler2(e, policy, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {policy.map((value) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, policy, value)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={value.id}
                                    >
                                        {value.policy}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {boards2.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler(e, value, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {companyPolicy.map((prod) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, companyPolicy, prod)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={prod.id}
                                    >
                                        {prod.policy}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <ListBtn>
                {loading ? (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                        Submit
                    </LoadingButton>
                ) : (
                    <Button onClick={onSave} variant="contained" color="success" disabled={company_id && companyPolicy.length ? false : true}>
                        Submit
                    </Button>
                )}
            </ListBtn>
        </Wrapper>
    );
};

export default List;
