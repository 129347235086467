import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditOffIcon from "@mui/icons-material/EditOff";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import FormControlLabel from "@mui/material/FormControlLabel";
import UpdateHotelPolicy from "./UpdatePolicy";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import Loading from "react-spinner-material";
import ENV from "../../../environment";
import axios from "axios";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const HotelPolicy = () => {
    const { hotelid } = useParams();

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;
    const validate = Yup.array().of(
        Yup.object().shape({
            id: Yup.number().integer().min(1).nullable().required("Field is required"),
            policy_id: Yup.number().integer().min(1).required("Field is required"),
            name: Yup.string().required("Field is required"),
            type: Yup.string().oneOf(["time", "boolean", "integer"]).required("Field is required"),
            value: Yup.mixed().when("type", {
                is: "time",
                then: Yup.string().matches(timeRegex, "Noto'g'ri vaqt formati").nullable().required("Field is required"),
                otherwise: Yup.mixed().when("type", {
                    is: "boolean",
                    then: Yup.boolean().nullable().required("Field is required"),
                    otherwise: Yup.mixed().when("type", {
                        is: "integer",
                        then: Yup.number().integer().nullable().required("Field is required"),
                        otherwise: Yup.mixed().notRequired(),
                    }),
                }),
            }),
        })
    );

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [data, setData] = useState([]);

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`${ENV.URL}/api/hdp/tts/adm/hotel/hotel-policy/${hotelid}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": ENV.CLIENTID,
                    "client-secret": ENV.SECRET,
                },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const update = () => {
        setUpdating(true);
    };

    return (
        <div className="container mt-3">
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loading radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <React.Fragment>
                    <Button style={{ float: "right" }} variant="outlined" onClick={update}>
                        Update
                    </Button>

                    <h4 className="mt-4" style={{ marginBottom: "1rem" }}>
                        {"List of policies"}
                    </h4>
                    <div className="hc-amenity no-select">
                        {data
                            .filter((e) => e.type === "time")
                            .map((amenity) => (
                                <div className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-ahj2mt-MuiTypography-root">
                                    <AccessAlarmsIcon /> {amenity.name}: {amenity.value?.slice(0, 5)}
                                </div>
                            ))}
                        {data
                            .filter((e) => e.name === "Cancel booking")
                            .map((amenity) => (
                                <div className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-ahj2mt-MuiTypography-root">
                                    <EditOffIcon /> {amenity.name}:{" "}
                                    {amenity.value === "null" || amenity.value === null
                                        ? "Can always cancel"
                                        : amenity.value === "0" || amenity.value === 0
                                        ? "Imposible cancel"
                                        : `Cancel to ${amenity.value * 24} hours`}
                                </div>
                            ))}
                    </div>
                    <div className="hc-amenity no-select">
                        {data
                            .filter((e) => e.type === "boolean")
                            .map((amenity) => (
                                <div>
                                    <FormControlLabel control={<Checkbox checked={amenity.value === "true"} />} label={amenity.name} />
                                </div>
                            ))}
                    </div>
                    <UpdateHotelPolicy open={updating} setOpen={setUpdating} data={data} getData={getData} />
                </React.Fragment>
            )}
        </div>
    );
};

export default HotelPolicy;
