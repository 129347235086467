import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "react-spinner-material";
import ENV from "../../environment";

const UpdateAlertPage = () => {
    const { alertId } = useParams();
    const navigate = useNavigate();
    const [alertData, setAlertData] = useState(null);
    const [freeHotels, setFreeHotels] = useState([]);
    const [assignedHotels, setAssignedHotels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertExpirationDate, setAlertExpirationDate] = useState("");
    const [assignMode, setAssignMode] = useState("all");
    const [draggedHotel, setDraggedHotel] = useState(null);

    const formatDateForInput = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(
            2,
            "0"
        )}T${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    };

    useEffect(() => {
        const fetchAlertData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${ENV.URL}/api/hdp/tts/adm/hotel/alerts/${alertId}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": ENV.CLIENTID,
                        "client-secret": ENV.SECRET,
                    },
                });

                const alertData = response.data;

                setAlertData(alertData);
                setAlertMessage(alertData.alertMessage || "");
                setAlertExpirationDate(formatDateForInput(alertData.alertExpirationDate));

                const associatedHotelIds = new Set(alertData.associated_hotels.map((hotel) => hotel.id));

                setAssignedHotels(alertData.associated_hotels || []);
                setFreeHotels(
                    alertData.all_hotels.filter((hotel) => !associatedHotelIds.has(hotel.id)) || []
                );

                if (alertData.all_hotels.length === alertData.associated_hotels.length) {
                    setAssignMode("all");
                } else {
                    setAssignMode("manual");
                }
            } catch (error) {
                toast.error("Failed to fetch alert or hotel data.");
            } finally {
                setLoading(false);
            }
        };

        fetchAlertData();
    }, [alertId]);

    const handleAssignToAllToggle = () => {
        if (assignMode === "all") {
            setFreeHotels([...freeHotels, ...assignedHotels]);
            setAssignedHotels([]);
        } else {
            setAssignedHotels([...assignedHotels, ...freeHotels]);
            setFreeHotels([]);
        }
    };

    const handleDragStart = (hotel, origin) => {
        if (assignMode === "manual") {
            setDraggedHotel({ hotel, origin });
        }
    };

    const handleDrop = (target) => {
        if (draggedHotel && assignMode === "manual") {
            const { hotel, origin } = draggedHotel;

            if (origin === "free" && target === "assigned") {
                setFreeHotels(freeHotels.filter((h) => h.id !== hotel.id));
                setAssignedHotels([...assignedHotels, hotel]);
            } else if (origin === "assigned" && target === "free") {
                setAssignedHotels(assignedHotels.filter((h) => h.id !== hotel.id));
                setFreeHotels([...freeHotels, hotel]);
            }
            setDraggedHotel(null);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);

            const dataToSend = {
                alertMessage,
                alertExpirationDate,
                hotels: assignMode === "all" 
                    ? [...freeHotels, ...assignedHotels].map((hotel) => hotel.id) 
                    : assignedHotels.map((hotel) => hotel.id),
            };

            await axios.put(
                `${ENV.URL}/api/hdp/tts/adm/hotel/alerts/${alertId}`,
                dataToSend,
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": ENV.CLIENTID,
                        "client-secret": ENV.SECRET,
                    },
                }
            );

            toast.success("Alert updated successfully!");
            navigate("/alerts");
        } catch (error) {
            toast.error("Failed to update alert.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
            </Box>
        );
    }

    return (
        <Box className="container mt-4">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Update Alert</Typography>
                    <Divider sx={{ my: 2 }} />
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        row
                        value={assignMode}
                        onChange={(e) => {
                            const mode = e.target.value;
                            setAssignMode(mode);
                            handleAssignToAllToggle();
                        }}
                    >
                        <FormControlLabel value="all" control={<Radio />} label="Assign to All Hotels" />
                        <FormControlLabel value="manual" control={<Radio />} label="Manual Selection" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Alert Message"
                        value={alertMessage}
                        onChange={(e) => setAlertMessage(e.target.value)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="datetime-local"
                        label="Expiration Date"
                        value={alertExpirationDate}
                        onChange={(e) => setAlertExpirationDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Paper
                        elevation={3}
                        sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => handleDrop("free")}
                    >
                        <Typography variant="h6">Free Hotels</Typography>
                        <Box sx={{ mt: 2, maxHeight: "500px", overflowY: "auto" }}>
                            {freeHotels.map((hotel) => (
                                <Box
                                    key={hotel.id}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        border: "1px solid #1976d2",
                                        borderRadius: 1,
                                        p: 1,
                                        mb: 1,
                                        cursor: assignMode === "manual" ? "grab" : "default",
                                    }}
                                    draggable={assignMode === "manual"}
                                    onDragStart={() => handleDragStart(hotel, "free")}
                                >
                                    {hotel.name}
                                </Box>
                            ))}
                            {freeHotels.length === 0 && (
                                <Typography variant="body2" color="textSecondary" align="center">
                                    No available free hotels
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper
                        elevation={3}
                        sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => handleDrop("assigned")}
                    >
                        <Typography variant="h6">Assigned Hotels</Typography>
                        <Box sx={{ mt: 2, maxHeight: "500px", overflowY: "auto" }}>
                            {assignedHotels.map((hotel) => (
                                <Box
                                    key={hotel.id}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        border: "1px solid #1976d2",
                                        borderRadius: 1,
                                        p: 1,
                                        mb: 1,
                                        cursor: assignMode === "manual" ? "grab" : "default",
                                    }}
                                    draggable={assignMode === "manual"}
                                    onDragStart={() => handleDragStart(hotel, "assigned")}
                                >
                                    {hotel.name}
                                </Box>
                            ))}
                            {assignedHotels.length === 0 && (
                                <Typography variant="body2" color="textSecondary" align="center">
                                    No hotels assigned
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={loading || !alertMessage || !alertExpirationDate}
                    >
                        Save Alert
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UpdateAlertPage;
