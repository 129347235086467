/* eslint-disable no-unused-vars */
import React from "react";
import WorkSpace from "./Settings";
import SpinnerTTS from "../spinner/Spinner";
import REACT_APP from "../../environment.js";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const Library = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    async function getData() {
        try {
            const headers = { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET };
            const response = await axios.get(`${REACT_APP.URL}/api/hdp/tts/adm/crypt/env`, { headers });
            setData(response.data.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            {loading ? (
                <div style={{ margin: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SpinnerTTS radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <WorkSpace data={data} />
            )}
        </div>
    );
};

export default Library;
