import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelContact from "../components/Hotel/HotelContact/ContactsTable.jsx";

const HotelContactPage = () => {
    return (
        <Box sx={{ width: "100%" }}>
            <HotelTabs />
            <HotelContact />
        </Box>
    );
};

export default HotelContactPage;
