import React, { useEffect, useState } from "react";
import axios from "axios";
import Action from "./Action";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Alert
} from "@mui/material";
import Spinner from "react-spinner-material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import REACT_APP from "../../../../environment";

const SetTable = () => {
    const { set_id } = useParams(); // Extract set_id from the URL
    const navigate = useNavigate();
    const [users, setUsers] = useState([]); // State to store users
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchUsers = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/users/${set_id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            })
            .then((res) => {
                setUsers(res.data.data); // Set the fetched users
                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                console.error("Error fetching users:", err);
                setError(true);
                setLoading(false);
                toast.error("Failed to fetch users.");
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []); // Fetch users on component mount

    return (
        <Box sx={{ width: "100%" }}>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100vh", // Full viewport height for centering
                    }}
                >
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </Box>
            ) : error ? (
                <Alert severity="error">Failed to load users. Please try again.</Alert>
            ) : (
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Middle Name</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow hover key={user.id}>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.first_name}</TableCell>
                                        <TableCell>{user.last_name}</TableCell>
                                        <TableCell>{user.middle_name || "N/A"}</TableCell>
                                        <TableCell>{user.phone || "N/A"}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.role_name}</TableCell>
                                        <TableCell>
                                            {user.account_status === 1 ? "Active" : "Inactive"}
                                        </TableCell>
                                        <TableCell>
                                            <Action
                                                set={user}
                                                id={user.id}
                                                fetchSets={fetchUsers}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Box>
    );
};

export default SetTable;
